import React from "react";
import EventLine from "../atoms/EventLine";

function UpcomingEvents({ upcomingEvents }) {
  return (
    <div className="mt-8">
      {upcomingEvents.map((ue, idx) => (
        <EventLine
          key={idx}
          name={ue.eventName}
          date={ue.eventDate}
          description={ue.eventDescription?.eventDescription}
        />
      ))}
    </div>
  );
}

export default UpcomingEvents;

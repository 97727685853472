import React from "react";
import MusicNote from "../../images/music-note.svg";

function HorizontalRule() {
  return (
    <div className="flex w-11/12 items-center">
      <hr className="h-1 bg-yellow-600 border-0 w-full rounded-l-full" />
      <MusicNote className="w-24 h-full mx-10 stroke-current fill-current text-yellow-500" />
      <hr className="h-1 bg-yellow-600 border-0 w-full rounded-r-full" />
    </div>
  );
}

export default HorizontalRule;

import React from "react";
import Socials from "./Socials";

const styles = {
  container: {
    base: "bg-blue-50 flex text-center items-center flex-col border-yellow-500 border-b-8 py-4 font-heading fixed top-0 w-screen z-50",
    lg: "lg:bg-opacity-80 lg:py-16 lg:relative",
    xl: "xl:mt-36",
  },
  header: {
    base: "text-2xl font-bold",
    lg: "lg:text-8xl lg:font-normal",
  },
  subHeader: {
    base: "text-lg mt-2",
    lg: "lg:text-4xl lg:mt-16",
  },
};

function getStyles(component) {
  return `${styles[component].base} ${styles[component].lg}`;
}

function Header() {
  return (
    <div className={getStyles("container")}>
      <h1 className={getStyles("header")}>Rosemary Braddy</h1>
      <h2 className={getStyles("subHeader")}>Mezzo-Soprano</h2>
      <Socials />
    </div>
  );
}

export default Header;

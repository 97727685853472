import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const styles = {
  container: {
    base: "flex justify-center h-full items-center w-full",
    sm: "",
    lg: "",
    xl: "",
  },
};

function getStyles(component) {
  return `${styles[component].base} ${styles[component].sm} ${styles[component].lg} ${styles[component].xl}`;
}

function Hero({ src }) {
  return (
    <div className={getStyles("container")}>
      <GatsbyImage
        image={getImage(src)}
        className="w-full h-72"
        imgStyle={{ objectFit: "contain" }}
      />
    </div>
  );
}

Hero.propTypes = {
  src: PropTypes.object,
};

export default Hero;

import React from "react";
import PropTypes from "prop-types";

function HeaderAndBody({ header, body }) {
  return (
    <>
      <h1 className="text-4xl font-bold lg:font-normal lg:text-5xl xl:text-6xl font-heading">
        {header}
      </h1>
      <div className="mt-8">{body}</div>
    </>
  );
}

HeaderAndBody.propTypes = {
  header: PropTypes.string,
  body: PropTypes.node,
};

export default HeaderAndBody;

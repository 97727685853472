import React from "react";
import HorizontalRule from "../atoms/HorizontalRule";

const styles = {
  container: {
    base: "self-center w-full flex flex-col-reverse items-start mb-4",
    sm: "",
    md: "",
    lg: "lg:pl-16 lg:mb-8 lg:flex-row-reverse lg:justify-between",
    xl: "xl:pl-16 xl:mb-10",
  },
  box: {
    base: "bg-white bg-opacity-90 rounded-2xl border-2 border-yellow-500 px-6 py-8 ring-4 ring-yellow-500 ring-opacity-30",
    sm: "sm:min-h-soundcloudplayer-sm w-full",
    md: "md:pt-16 md:min-h-soundcloudplayer-md md:mr-8",
    lg: "lg:px-8 lg:pt-20 lg:max-w-xl lg:ml-14",
    xl: "xl:pt-18 xl:max-w-3xl",
  },
  element: {
    base: "w-full flex justify-start mb-8 min-h-soundcloudplayer min-w-full",
    sm: "sm:min-h-soundcloudplayer-sm",
    md: "md:min-h-soundcloudplayer-md md:self-stretch md:mb-8",
    lg: "lg:ml-0 lg:mr-0 lg:max-w-xl lg:min-w-0",
    xl: "xl:ml-0",
  },
  rule: {
    base: "w-full flex justify-center mb-16",
    sm: "",
    md: "md:mb-24",
    lg: "",
    xl: "xl:mb-48 xl:max-w-6xl",
  },
};

function getStyles(component) {
  return `${styles[component].base} ${styles[component].sm} ${styles[component].md} ${styles[component].lg} ${styles[component].xl}`;
}

function ContentNextToElement({ content, element }) {
  return (
    <>
      <div className={`${getStyles("container")}`}>
        <div className={getStyles("box")}>
          <div className="">{content}</div>
        </div>
        <div className={getStyles("element")}>{element}</div>
      </div>
      <div className={"w-full flex justify-center"}>
        <div className={`${getStyles("rule")}`}>
          <HorizontalRule />
        </div>
      </div>
    </>
  );
}

export default ContentNextToElement;

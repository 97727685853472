import React from "react";
import ContentNextToElement from "../molecules/ContentNextToElement";
import Hero from "../atoms/Hero";
import HeaderAndBody from "../atoms/HeaderAndBody";
import PropTypes from "prop-types";
import { getImage } from "gatsby-plugin-image";

function AboutMe({ aboutMe }) {
  return (
    <ContentNextToElement
      content={
        <HeaderAndBody
          header={aboutMe.heading}
          body={aboutMe.content.content}
        />
      }
      element={<Hero src={getImage(aboutMe.image)} />}
    />
  );
}

AboutMe.propTypes = {
  heading: PropTypes.string,
  content: PropTypes.shape({ content: PropTypes.string }),
  image: PropTypes.shape({ fluid: PropTypes.object }),
};

export default AboutMe;

import React from "react";
import { Background, Parallax } from "react-parallax";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

function MainTemplate({ children, bgImgSrc, heading }) {
  return (
    <div>
      <Parallax strength={1000} className="fixed overflow-y-auto text-gray-600">
        <Background className="w-screen">
          <GatsbyImage
            image={getImage(bgImgSrc)}
            className="w-full hidden lg:block"
            style={{
              height: "3200px",
              filter: "blur(6px)",
              left: "-100px",
            }}
          />
          <GatsbyImage
            image={getImage(bgImgSrc)}
            className="w-full hidden lg:hidden md:block"
            style={{
              height: "2900px",
              filter: "blur(6px)",
              left: "-10px",
            }}
          />
          <GatsbyImage
            image={getImage(bgImgSrc)}
            className="w-full hidden md:hidden sm:block"
            style={{ height: "300vh", filter: "blur(6px)", left: "-50px" }}
          />
          <GatsbyImage
            image={getImage(bgImgSrc)}
            className="w-full sm:hidden"
            style={{ height: "250vh", filter: "blur(6px)" }}
          />
        </Background>
        <div className="lg:w-full lg:flex lg:flex-col lg:items-center">
          <div>{heading}</div>
          <div className="pt-48 px-6 w-full md:pt-48 lg:px-8 lg:pt-6 xl:px-0 xl:max-w-screen-2xl">
            {children}
          </div>
        </div>
      </Parallax>
    </div>
  );
}

export default MainTemplate;

import React from "react";
import YoutubeEmbed from "../atoms/YoutubeEmbed";

function Media() {
  return (
    <div className="flex justify-center">
      <YoutubeEmbed />
    </div>
  );
}

export default Media;

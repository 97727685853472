import React from "react";
import HorizontalRule from "../atoms/HorizontalRule";

const styles = {
  container: {
    base: "self-center w-full flex flex-col-reverse items-center mb-4",
    sm: "",
    md: "",
    lg: "lg:pl-16 lg:mb-8",
    xl: "xl:pl-16 xl:mb-10",
  },
  box: {
    base: "bg-white bg-opacity-90 rounded-2xl border-2 border-yellow-500 px-6 py-8 ring-4 ring-yellow-500 ring-opacity-30 w-full",
    sm: "sm:mt-8",
    md: "md:pt-16 md:mb-16",
    lg: "lg:px-8 lg:pt-20 lg:pb-12 lg:max-w-xl",
    xl: "xl:pt-18 xl:max-w-3xl",
  },
  element: {
    base: "w-full mb-8 flex justify-center",
    sm: "sm:mb-4",
    md: "",
    lg: "lg:ml-8",
    xl: "xl:ml-0",
  },
  rule: {
    base: "w-full flex justify-center mb-16",
    sm: "",
    md: "md:mb-24",
    lg: "",
    xl: "xl:mb-32 xl:max-w-6xl",
  },
};

function getStyles(component) {
  return `${styles[component].base} ${styles[component].sm} ${styles[component].md} ${styles[component].lg} ${styles[component].xl}`;
}

function ContentNextToElement({ content, element, reverseColumns }) {
  return (
    <>
      <div
        className={`${getStyles("container")} ${
          reverseColumns ? "lg:flex-row-reverse" : "lg:flex-row"
        }`}
      >
        <div className={getStyles("box")}>
          <div className="">{content}</div>
        </div>
        <div className={getStyles("element")}>{element}</div>
      </div>
      <div className="w-full flex justify-center">
        <div className={`${getStyles("rule")}`}>
          <HorizontalRule />
        </div>
      </div>
    </>
  );
}

export default ContentNextToElement;

import ReactPlayer from "react-player";
import React from "react";

function SoundCloudEmbed() {
  return (
    <div className="max-w-xl w-full min-w-full min-h-soundcloudplayer sm:min-h-soundcloudplayer-sm md:min-h-soundcloudplayer-md rounded-2xl overflow-hidden border-2 border-yellow-500 ring-4 ring-yellow-500 ring-opacity-30">
      <div className="w-full h-full -top-0.5 -left-1 relative">
        <ReactPlayer
          url="https://soundcloud.com/rosemarybraddymezzo/sets/opera"
          width="102%"
          height="102%"
        />
      </div>
    </div>
  );
}

export default SoundCloudEmbed;

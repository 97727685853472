import * as React from "react";
import { graphql } from "gatsby";
import RosemaryBraddy from "../components/pages/rosemaryBraddy";
import Seo from "../components/utils/seo";
import { motion } from "framer-motion";

const RosemaryBraddyPage = ({ data }) => {
  return (
    <>
      <Seo metaProperties={data.allContentfulSeoProperties.nodes[0]} />
      <div className="bg-white">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <RosemaryBraddy
            bgImgSrc={data.allContentfulAsset.nodes[0]}
            aboutMe={
              data.allContentfulWebsiteContentSections.nodes[0]
                .contentSections[0]
            }
            upcomingEvents={
              data.allContentfulUpcomingEventsList.nodes[0].events
            }
          />
        </motion.div>
      </div>
    </>
  );
};

export default RosemaryBraddyPage;

export const pageQuery = graphql`
  query {
    allContentfulAsset(
      filter: { contentful_id: { eq: "6pmJdvXJKVMLYBrGDqqqee" } }
    ) {
      nodes {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    allContentfulWebsiteContentSections(
      filter: { contentful_id: { eq: "1GhN3b24BYSA558w4wPkfl" } }
    ) {
      nodes {
        contentSections {
          heading
          content {
            content
          }
          image {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
    }
    allContentfulUpcomingEventsList {
      nodes {
        events {
          eventDescription {
            eventDescription
          }
          eventName
          eventDate
        }
      }
    }
    allContentfulSeoProperties {
      nodes {
        description {
          description
        }
        image {
          gatsbyImageData(layout: FIXED)
        }
        title
      }
    }
  }
`;

import React from "react";

function YoutubeEmbed() {
  return (
    <div className="max-w-4xl lg:max-w-3xl w-full min-h-soundcloudplayer sm:min-h-soundcloudplayer-sm md:min-h-soundcloudplayer-md xl:min-h-full xl:max-h-120 rounded-2xl overflow-hidden border-2 border-yellow-500 ring-4 ring-yellow-500 ring-opacity-30">
      <div className="w-full h-full relative">
        <iframe
          width="100%"
          height="750"
          src="https://www.youtube.com/embed/videoseries?list=PLKeaK1kdKWh_vxHoh4Jw0OSdmOvES3_0s"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
}

export default YoutubeEmbed;

import React from "react";
import MainTemplate from "../templates/main-template";
import Header from "../atoms/Header";
import AboutMe from "../organisms/AboutMe";
import LatestReleases from "../organisms/LatestReleases";
import Media from "../organisms/Media";

function RosemaryBraddy({ bgImgSrc, aboutMe, upcomingEvents }) {
  return (
    <MainTemplate bgImgSrc={bgImgSrc} heading={<Header />}>
      <div className="mb-8 xl:mb-24">
        <AboutMe aboutMe={aboutMe} />
      </div>

      <div className="mb-8 xl:mb-24 h-full">
        <LatestReleases upcomingEvents={upcomingEvents} />
      </div>

      <div className="mb-8 xl:mb-24 h-full">
        <Media />
      </div>
    </MainTemplate>
  );
}

export default RosemaryBraddy;

import MusicNote from "../../images/music-note.svg";
import React from "react";

const styles = {
  container: {
    base: "mt-12",
    sm: "",
    lg: "",
    xl: "",
  },
  headerContainer: {
    base: "flex flex-col items-center",
    sm: "sm:flex-row sm:justify-between",
    lg: "",
    xl: "",
  },
  titleContainer: {
    base: "flex flex-col-reverse items-center",
    sm: "sm:flex-row sm:justify-start",
    lg: "",
    xl: "",
  },
  title: {
    base: "font-bold italic font-heading text-center",
    sm: "",
    lg: "",
    xl: "",
  },
  note: {
    base: "w-4 h-full stroke-current fill-current text-yellow-500 my-2",
    sm: "sm:my-0 sm:mr-2",
    lg: "lg:w-8",
    xl: "xl:w-6",
  },
  date: {
    base: "italic",
    sm: "",
    lg: "lg:w-36 lg:ml-4",
    xl: "",
  },
};

function getStyles(component) {
  return `${styles[component].base} ${styles[component].sm} ${styles[component].lg} ${styles[component].xl}`;
}

function EventLine({ name, date, description }) {
  return (
    <div className={getStyles("container")}>
      <div className={getStyles("headerContainer")}>
        <div className={getStyles("titleContainer")}>
          <MusicNote className={getStyles("note")} />
          <div className={getStyles("title")}>{name}</div>
        </div>
        <div className={getStyles("date")}>
          {new Intl.DateTimeFormat("en-gb", {
            day: "numeric",
            month: "short",
            year: "numeric",
          }).format(Date.parse(date))}
        </div>
      </div>
      <hr className="h-0.5 my-4 bg-yellow-500 border-0 w-full rounded-full" />
      <div className="italic">{description}</div>
    </div>
  );
}

export default EventLine;

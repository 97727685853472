import React from "react";
import HeaderAndBody from "../atoms/HeaderAndBody";
import PropTypes from "prop-types";
import ElementNextToContent from "../molecules/ElementNextToContent";
import SoundCloudEmbed from "../atoms/SoundCloudEmbed";
import UpcomingEvents from "../molecules/UpcomingEvents";

function LatestReleases({ upcomingEvents }) {
  return (
    <ElementNextToContent
      reverseColumns={true}
      content={
        <HeaderAndBody
          header="Upcoming Events"
          body={<UpcomingEvents upcomingEvents={upcomingEvents} />}
        />
      }
      element={<SoundCloudEmbed />}
    />
  );
}

LatestReleases.propTypes = {
  heading: PropTypes.string,
  content: PropTypes.shape({ content: PropTypes.string }),
};

export default LatestReleases;
